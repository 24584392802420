* {
    box-sizing: border-box;
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;

    // @media (max-width: $br-md) {
    //     display: block;
    // }
}

.container {
    max-width: 1140px;
    margin : 0 auto;
    padding: 0 25px;
}

.container-footer {
    max-width: 1340px;
    margin : 0 auto;
    padding: 0 25px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;

    @media (max-width: $br-md) {
        margin: 80px 0;
    }

}

.logo {
    @include aspect-ratio(452,419);
    background-image: url('../assets/img/stilhauer-florian-kranawendter-logo.png');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 300px;

    @media (max-width: $br-md) {
        margin-bottom: 36px;
    }
}


.background {
    position: fixed;
    top: 0;
    left: 0;
    background-image: url('../assets/img/stilhauer-florian-kranawendter.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100vh;
    z-index: -1;
    pointer-events: none;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 17, 31, 0.84);
    }

}

.image-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -1;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 17, 31, 0.84);
    }

    .slider-image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 5s ease;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        &.is-active {
            opacity: 1;
        }
    }
}

.intro {
    display: flex;
    flex-direction: column;

    @media (min-width: $br-md) {
        flex-direction: row;
    }

    .intro-left {

        @media (min-width: $br-md) {
            margin-right: 23px;
        }
    }

    .intro-right {
        font-style: italic;

        @media (min-width: $br-md) {
            margin-left: 23px;
        }
    }
}

.contact {
    width: 100%;
    text-align: center;

    @media (min-width: 1000px) {
        position: relative;
        top: -28px;
    }
}

.link {
    color: $very-light-brown;
    text-decoration: none;
    display: inline-block;
    position: relative;

    &:visited {
        color: $very-light-brown;
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $very-light-brown;
        left: 0;
        bottom: -8px;
        opacity: 0;
        transition: all 0.3s ease-out;
    }

    &:hover {
        &:after {
            opacity: 1;
            bottom: -5px;
        }
    }

    &.instagram {
        position: relative;
        padding-left: 18px;

        &:before {
            content: '';
            position: absolute;
            width: 15px;
            height: 15px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-image: url('../assets/svg/instagram.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;

        }
    }

    &.is-active {
        @media (min-width: 1000px) {
            &:after {
                opacity: 1;
                bottom: -5px;
            }
        }
    }

    &.is-darker {
        color: darken($very-light-brown, 10%);
    }
}

.single {

    .single-logo {
        @include aspect-ratio(452,419);
        display: block;
        width: 240px;
        background-image: url('../assets/img/stilhauer-florian-kranawendter-logo.png');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        margin: 0 auto;
        margin-bottom: 36px;
    }

    .content {
        margin: 110px 0;
        display: block;
        width: 100%;
    }

    ul {
        li {
            line-height: 1.44;
        }
    }
}
