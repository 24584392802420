/***************************
**** Grid sizes
***************************/

$br-sm: 480px;
$br-md: 765px;
$br-lg: 1020px;
$br-xl: 1140px;
$br-xxl: 1600px;
$br-hd: 1920px;

// Custom Nav Breakpoint
$br-nav: 960px;

// colors

$white: #fff;
$black: #000;
$very-light-blue: #e5e9f3;
$very-light-brown: #ad976d;
