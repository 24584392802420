.footer {
    width: 100%;
    margin-bottom: 28px;
    font-size: 16px;

    ul {
        list-style: none;
        color: $very-light-brown;
        display: flex;
        padding: 0;
        margin: 0;

    }

    .footer-inner {
        display: flex;

        .footer-left {
            li {

                margin-right: 9px;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    left: 100%;
                    margin-left: 4px;
                    top: 50%;
                    width: 2px;
                    height: 2px;
                    border-radius: 50%;
                    background-color: $very-light-brown;
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .footer-right {
            margin-left: auto;

            li {
                margin-right: 28px;

                &:last-child {
                    margin-right: 0;
                }

            }
        }
    }

    @media (max-width: 1000px) {
        .footer-inner {
            display: block;

            ul {
                margin: 0;
                display: block;
                text-align: center;

                li {
                    display: block;
                    line-height: 1.44;

                }
            }

            .footer-left, .footer-right {
                li {
                    margin: 0;
                }
            }

            .footer-left {
                li {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}
