/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/roboto-v20-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Roboto Thin'), local('Roboto-Thin'),
       url('../fonts/roboto-v20-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v20-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v20-latin-100.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v20-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v20-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/roboto-v20-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
       url('../fonts/roboto-v20-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v20-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v20-latin-100italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v20-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v20-latin-100italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   src: url('../fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
//   src: local('Roboto Light'), local('Roboto-Light'),
//        url('../fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('../fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
//        url('../fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
//        url('../fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
// }
// /* roboto-300italic - latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: italic;
//   font-weight: 300;
//   src: url('../fonts/roboto-v20-latin-300italic.eot'); /* IE9 Compat Modes */
//   src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
//        url('../fonts/roboto-v20-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('../fonts/roboto-v20-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../fonts/roboto-v20-latin-300italic.woff') format('woff'), /* Modern Browsers */
//        url('../fonts/roboto-v20-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
//        url('../fonts/roboto-v20-latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
// }
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('../fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}