body {
    color: $white;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
}

p {
    font-weight: 100;
    line-height: 1.44;
    color: $very-light-blue;

    b {
        letter-spacing: 1.5px;
    }
}

h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
}

h2 {
    font-size: 22px;
}
