.button {

    display: inline-block;
    color: $very-light-blue;
    text-decoration: none;
    border: 1px solid $very-light-brown;
    padding: 12px 28px;

    &.will-pulse {
        &:hover {
            animation: pulse 1.5s infinite ease-in-out;
        }
    }

    @keyframes pulse {

        0% { border-color: $very-light-brown; }
        50% { border-color: darken($very-light-brown, 30%); }
        100% { border-color: $very-light-brown; }

    }
}
